.player-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .video-container {
    width: 100%;
  }
  
  .playlist-container {
    width: 100%;
  }
  
  .playlist {
    padding: 16px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .playlist ul {
    list-style-type: none;
    padding: 0;
  }
  
  .playlist ul li {
    margin-bottom: 8px;
  }
  
  .playlist ul li button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .playlist ul li button:hover {
    background-color: #ddd;
  }
  
  .playlist ul li.active button {
    font-weight: bold;
    color: #007bff;
  }
  .container {
  max-width: 1200px;
}
.course-modules {
  max-height: 80vh;
  overflow-y: auto;
}

.bg-white {
  background-color: #fff;
}
