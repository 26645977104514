/* src/components/AdminLayout.css */
.admin-layout {
    display: flex;
    min-height: 100vh;
  }
  
  .admin-nav {
    width: 200px;
    background: #333;
    color: #fff;
    padding: 20px;
  }
  
  .admin-nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-nav ul li {
    margin-bottom: 10px;
  }
  
  .admin-nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .admin-nav ul li a:hover {
    text-decoration: underline;
  }
  
  .admin-content {
    flex-grow: 1;
    padding: 20px;
  }
  